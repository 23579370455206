<template>
  <v-dialog width="400px" persistent v-model="dialog" v-bind="attrs">
    <template v-slot:activator="{ attrs }">
      <v-btn @click="openCreateLog" color="primary" v-bind="attrs">Add Log</v-btn>
    </template>
    <v-card>
      <v-toolbar v-if="!editConfig.isEdit" dark color="primary" dense flat>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Create New Log</v-toolbar-title>
      </v-toolbar>
      <v-toolbar v-else dark color="yellow darken-3" dense flat>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Log</v-toolbar-title>
      </v-toolbar>
      <v-container v-if="!editConfig.loading">
        <div class="log-form">
          <v-autocomplete
            v-model="formInputs.customerId"
            label="Client"
            outlined
            dense
            item-value="id"
            item-text="name"
            :loading="customerServerActions.loading"
            :items="customerServerActions.list"
            hide-details="auto"
            @change="onCustomerChange"
            :error-messages="formServerActions.errors.customerId"
          ></v-autocomplete>
          <v-combobox
            label="Elevator"
            outlined
            dense
            hide-details="auto"
            :loading="elevatorSuggestions.loading"
            :items="elevatorSuggestions.list"
            :error-messages="formServerActions.errors.elevator"
            v-model="formInputs.elevator"
          ></v-combobox>
          <v-autocomplete
            label="Status Start"
            outlined
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.statusBefore"
            v-model="formInputs.statusBefore"
            :items="['operational', 'non-operational']"
          ></v-autocomplete>

          <v-text-field
            label="Date Start"
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.dateStart"
            v-model="formInputs.dateStart"
          ></v-text-field>

          <v-text-field
            label="Actions"
            outlined
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.actions"
            v-model="formInputs.actions"
            clearable
          ></v-text-field>
          <v-textarea
            label="Remarks"
            outlined
            rows="3"
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.remarks"
            v-model="formInputs.remarks"
            clearable
          ></v-textarea>
          <v-divider></v-divider>
          <v-autocomplete
            label="Status End"
            outlined
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.statusAfter"
            v-model="formInputs.statusAfter"
            :items="['non-selected', 'operational', 'non-operational']"
          ></v-autocomplete>
          <v-text-field
            label="Date End"
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            :error-messages="formServerActions.errors.dateEnd"
            v-model="formInputs.dateEnd"
          ></v-text-field>
          <v-btn
            v-if="!editConfig.isEdit"
            color="primary"
            @click="addLog"
            :loading="formServerActions.loading"
            >Add Log</v-btn
          >
          <v-btn
            v-else
            color="yellow darken-3"
            dark
            elevation="0"
            :loading="formServerActions.loading"
            @click="updateLog"
          >
            Update
          </v-btn>
        </div>
      </v-container>
      <v-container v-else>
        <div class="log-form" style="width: 100%; position: relative;">
          <v-skeleton-loader
            type="text, text, text, text, text, text, button"
            min-width="100%"
          ></v-skeleton-loader>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scope>
.log-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .v-skeleton-loader {
    .v-skeleton-loader__text {
      height: 40px;
      &:nth-child(6) {
        height: 80px;
      }
    }
    .v-skeleton-loader__button {
      width: 100%;
    }
  }
}
</style>
<script script setup>
import appAxios from '@/libs/app-axios'
import { objectKeys, resetForm } from '@/libs/utils'
import { AxiosError } from 'axios'
import { onBeforeMount, reactive, ref, useAttrs } from 'vue'

const attrs = useAttrs()
const dialog = ref(false)
const axiosInstance = appAxios()

/**
 * Description
 * @returns {FormInputs}
 */
const defaultFormInputs = () => ({
  elevator: null,
  statusBefore: null,
  statusAfter: null,
  customerId: null,
  dateStart: new Date().toLocaleString('sv-SE').replace(' ', 'T'),
  dateEnd: null,
  actions: null,
  remarks: null,
})

const emits = defineEmits(['log-added'])

const formInputs = reactive(defaultFormInputs())

/**
 * @type {{loading: boolean, errors: FormServerActionsError}}
 */
const formServerActions = reactive({
  loading: false,
  errors: {},
})

const customerServerActions = reactive({
  loading: false,
  list: [],
})

const elevatorSuggestions = reactive({
  loading: false,
  list: [],
})

const defaultEditConfig = () =>
  /** @type {{
   * isEdit: boolean
   * loading: boolean
   * data: Partial<FormInputs & { id: number }>
  }} */
  ({
    isEdit: false,
    loading: false,
    data: {},
  })

const editConfig = reactive(defaultEditConfig())

const getElevatorSuggestions = (() => {
  /** @type {null|AbortController} */
  let controller = null
  /**
   * Description
   * @param {string} customerId
   */
  return async customerId => {
    try {
      controller?.abort?.()
      controller = new AbortController()
      elevatorSuggestions.loading = true
      const { data } = await axiosInstance.get('elevator-trouble-logs/elevator-suggestions', {
        params: { customerId },
      })
      elevatorSuggestions.list = data
    } catch (e) {
      console.error(e)
    } finally {
      elevatorSuggestions.loading = false
    }
  }
})()

const openCreateLog = () => {
  editConfig.isEdit = false
  editConfig.data = {}
  dialog.value = true
  const defaultInput = defaultFormInputs()
  objectKeys(formInputs).forEach(key => {
    /** @type {any} */
    const input = defaultInput[key]
    formInputs[key] = input
  })
}

/**
 * Description
 * @param {string} val
 */
const onCustomerChange = val => {
  getElevatorSuggestions(val)
}

const getElevatorTroubleLog = (() => {
  /** @type {null|AbortController} */
  let controller = null
  /**
   * Description
   * @param {number} elevatorTroubleLogId
   */
  return async elevatorTroubleLogId => {
    try {
      editConfig.loading = true
      controller?.abort?.()
      controller = new AbortController()
      const { data } = await axiosInstance.get(`elevator-trouble-logs/${elevatorTroubleLogId}`, {
        signal: controller.signal,
      })
      editConfig.data = { ...data }
      objectKeys(formInputs).forEach(key => {
        formInputs[key] = data[key]
      })
    } catch (e) {
      console.error(e)
      alert('oops something went wrong')
      dialog.value = false
    } finally {
      editConfig.loading = false
    }
  }
})()

/**
 * Description
 * @param {number} elevatorTroubleLogId
 */
const edit = elevatorTroubleLogId => {
  getElevatorTroubleLog(elevatorTroubleLogId)
  dialog.value = true
  editConfig.isEdit = true
}

defineExpose({
  edit,
})

/**
 * Description
 * @param {AbortController} [controller]
 */
const getCustomers = async controller => {
  try {
    customerServerActions.loading = true
    const { data } = await axiosInstance.get('customers', {
      params: {
        categories: ['MS', 'IRP'],
      },
      signal: controller?.signal,
    })
    customerServerActions.list = data
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response.status === 500) {
        console.error(e.message)
        setTimeout(() => getCustomers, 3000)
      }
    }
    if (e instanceof Error) {
      console.error(e)
      alert(e.message)
    }
  } finally {
    customerServerActions.loading = false
  }
}

const resetFormInputs = () => {
  const defaultInputs = defaultFormInputs()
  const formInputKeys = objectKeys(defaultInputs)
  formInputKeys.forEach(key => {
    const included = ['elevator', 'dateTime']
    if (!included.includes(key)) return
    /** @type {any} */
    const defaultInput = defaultInputs[key]
    formInputs[key] = defaultInput
  })
}

const addLog = async () => {
  try {
    if (formServerActions.loading) return
    formServerActions.loading = true
    formServerActions.errors = {}
    const data = { ...formInputs }
    await axiosInstance.post('elevator-trouble-logs', data)
    resetFormInputs()
    emits('log-added')
    alert('successfully added elevator log')
  } catch (e) {
    if (e instanceof AxiosError) {
      console.error(e.message)
      if (e.response.status === 500) {
        setTimeout(() => getCustomers, 3000)
        return
      }
      if (e.response.status === 422) {
        formServerActions.errors = e.response?.data?.errors ?? {}
        return
      }
    }
    if (e instanceof Error) {
      console.error(e)
      alert(e.message)
      return
    }
  } finally {
    formServerActions.loading = false
  }
}

const updateLog = async () => {
  try {
    if (formServerActions.loading) return
    const elevatorTroubleLogId = editConfig.data?.id
    if (!elevatorTroubleLogId) throw new Error('editConfig.data?.id is null | undefined')
    formServerActions.loading = true
    formServerActions.errors = {}
    const data = { ...formInputs }
    await axiosInstance.put(`elevator-trouble-logs/${elevatorTroubleLogId}`, data)
    emits('log-added')
    alert('successfully updated elevator log')
  } catch (e) {
    if (e instanceof AxiosError) {
      console.error(e.message)
      if (e.response.status === 500) {
        setTimeout(() => getCustomers, 3000)
        return
      }
      if (e.response.status === 422) {
        formServerActions.errors = e.response?.data?.errors ?? {}
        return
      }
    }
    if (e instanceof Error) {
      console.error(e)
      alert(e.message)
      return
    }
  } finally {
    formServerActions.loading = false
  }
}

const closeDialog = () => {
  dialog.value = false
  resetForm(formInputs, defaultFormInputs())
}

onBeforeMount(() => {
  getCustomers()
})

/**
 * @typedef {Object} FormServerActionsError
 * @property {string[]} [elevator]
 * @property {string[]} [statusBefore]
 * @property {string[]} [statusAfter]
 * @property {string[]} [customerId]
 * @property {string[]} [dateStart]
 * @property {string[]} [dateEnd]
 * @property {string[]} [actions]
 * @property {string[]} [remarks]
 *
 * @typedef {Object} FormInputs
 * @property {null | string} elevator
 * @property {null | 'operational' | 'non-operational'} statusBefore
 * @property {null | 'operational' | 'non-operational'} statusAfter
 * @property {null | string} customerId
 * @property {null | string} dateStart
 * @property {null | string} dateEnd
 * @property {null | string} actions
 * @property {null | string} remarks
 */
</script>
