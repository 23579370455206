<template>
  <div>
    <div class="actions">
      <ElevatorTroubleLogCreate
        @log-added="logAdded"
        color="primary"
        ref="elevatorTroubleLogCreateRef"
      ></ElevatorTroubleLogCreate>
    </div>
    <ElevatorTroubleLogTable ref="elevatorTroubleLogTableRef" @on-edit="onEdit" />
  </div>
</template>
<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 10px;
}
</style>
<script script setup>
import { ref } from 'vue'
import ElevatorTroubleLogTable from '@/views/admin/elevator-trouble-logs/ElevatorTroubleLogTable.vue'
import ElevatorTroubleLogCreate from './ElevatorTroubleLogCreate.vue'

const elevatorTroubleLogTableRef = ref()
const elevatorTroubleLogCreateRef = ref()

/**
 * Description
 * @param {number} elevatorTroubleLogId
 */
const onEdit = elevatorTroubleLogId => {
  elevatorTroubleLogCreateRef.value?.edit(elevatorTroubleLogId)
}

const logAdded = () => {
  elevatorTroubleLogTableRef.value?.getElevatorLogs?.()
}
</script>
