<template>
  <div>
    <v-data-table :headers="headers" dense :items="tableServerActions.list">
      <template v-slot:item.config="{ item }">
        <div v-if="!item.locked">
          <v-btn text small color="yellow darken-3" @click="onEdit(item)">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn :loading="locking.has(item.id)" @click="lock(item.id)" text small color="blue">
            <v-icon>mdi-lock</v-icon>
          </v-btn>
        </div>
        <div v-else-if="authAccessLevel >= 7">
          <v-btn :loading="locking.has(item.id)" @click="unlock(item.id)" text small color="blue">
            <v-icon>mdi-lock-open</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.date_start="{ item }">
        <div>
          {{ formatDateTimeUS(new Date(item.dateStart)) }}
        </div>
      </template>
      <template v-slot:item.date_end="{ item }">
        <div>
          {{ item.dateEnd ? formatDateTimeUS(new Date(item.dateEnd)) : 'ONGOING' }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script script setup>
import appAxios from '@/libs/app-axios'
import { formatDateTimeUS, formatDateUS } from '@/libs/utils'
import { useStore } from '@/store'
import { computed, onBeforeMount, reactive, ref } from 'vue'

const axiosInstance = appAxios()
const store = useStore()

/** @type {ElevatorTroubleLogHeader[]} */
const headers = [
  { text: 'Elevator', value: 'elevator' },
  { text: 'Status Start', value: 'statusBefore' },
  { text: 'Status End', value: 'statusAfter' },
  { text: 'Client', value: 'customer.name' },
  { text: 'Date Start', value: 'date_start' },
  { text: 'Date End', value: 'date_end' },
  { text: 'Actions', value: 'actions' },
  { text: 'Remarks', value: 'remarks' },
  { text: 'Config', value: 'config' },
]

const tableServerActions = reactive({
  loading: false,
  list: [],
})

const authAccessLevel = computed(() => store.state.auth.accessLevel)

const emit = defineEmits(['on-edit'])

/**
 * Description
 * @param {ElevatorTroubleLog} item
 */
const onEdit = item => {
  emit('on-edit', item.id)
}

/**
 * Description
 * @param {AbortController} controller
 */
const getElevatorLogs = (() => {
  /** @type {null|AbortController} */
  let controller = null
  return async () => {
    try {
      controller?.abort?.()
      tableServerActions.loading = true
      controller = new AbortController()
      const { data } = await axiosInstance.get('elevator-trouble-logs', {
        signal: controller.signal,
      })
      tableServerActions.list = data
    } catch (e) {
      console.error(e)
      setTimeout(() => getElevatorLogs(), 3000)
    } finally {
      tableServerActions.loading = false
    }
  }
})()

const locking = ref(new Set())

/**
 * Description
 * @param {number} id
 */
const addLockingLoader = id => {
  locking.value.add(id)
  locking.value = new Set(Array.from(locking.value))
}

/**
 * Description
 * @param {number} id
 */
const removeLockingLoader = id => {
  locking.value.delete(id)
  locking.value = new Set(Array.from(locking.value))
}

/**
 * Description
 * @param {number} elevatorTroubleLogId
 */
const lock = async elevatorTroubleLogId => {
  try {
    if (locking.value.has(elevatorTroubleLogId)) return
    addLockingLoader(elevatorTroubleLogId)
    await axiosInstance.put(`/elevator-trouble-logs/${elevatorTroubleLogId}/lock`)
    alert('successfully locked the log')
    getElevatorLogs()
  } catch (e) {
    if (e instanceof Error) {
      console.error(e)
      alert(e.message)
    }
  } finally {
    removeLockingLoader(elevatorTroubleLogId)
  }
}

/**
 * Description
 * @param {number} elevatorTroubleLogId
 */
const unlock = async elevatorTroubleLogId => {
  try {
    if (locking.value.has(elevatorTroubleLogId)) return
    addLockingLoader(elevatorTroubleLogId)
    await axiosInstance.put(`/elevator-trouble-logs/${elevatorTroubleLogId}/unlock`)
    alert('successfully unlocked the log')
    getElevatorLogs()
  } catch (e) {
    if (e instanceof Error) {
      console.error(e)
      alert(e.message)
    }
  } finally {
    removeLockingLoader(elevatorTroubleLogId)
  }
}

onBeforeMount(() => {
  getElevatorLogs()
})

defineExpose({ getElevatorLogs })

/**
 * @typedef {Object} ElevatorTroubleLog
 * @property {number} id
 * @property {string} status
 * @property {string} elevator
 * @property {string} client
 * @property {string} date
 * @property {string} actions
 * @property {string} remarks
 *
 * @typedef {import('vuetify').DataTableHeader<ElevatorTroubleLog>} ElevatorTroubleLogHeader
 */
</script>
